import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useNavigate, useLocation } from 'react-router-dom';
import ProductDesign from './product-design';

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Featured project data
const featuredProjects = [
  {
    id: 3,
    name: 'Modernizing Extranet System',
    client: 'VILA VELHA INSURANCE BROKER',
    video: '/images/vl/Heading.mp4',
    image: '/images/vl/new-design-thumb.png',
    year: '2025',
    skills: ['Visual Design', 'User Experience', 'UI', 'Prototyping', 'Research'],
    tools: ['framer']
  },
  {
    id: 2,
    name: 'Conversion-Focused Tourism Landing Page',
    client: 'BAHIA VAN TOUR',
    image: '/images/vantour/d-van-tour-thumb.png',
    year: '2024',
    skills: ['Visual design', 'User Experience', 'Prototyping'],
    tools: ['figma', "photoshop"],
    liveLink: 'https://www.figma.com/proto/uLWAsONKTcmhrZC0z0zvdS/Ariane-Souza-Projetos?node-id=929-24&t=ow9rhSQmMhTRQLFp-1' 
  },
  {
    id: 1,
    name: 'Progressive Web App for Tabletop Ordering',
    client: 'KINKOA',
    video: '/images/kinkoa/thumb-vid.mp4',
    image:'/images/kinkoa/k-thumb.jpg',
    year: '2023',
    skills: ['User experience', 'Prototyping','Art Direction'],
    tools: ['figma', 'photoshop','dimension']
  },

];

// Additional projects data
const additionalProjects = [
  {
    id: 5,
    name: 'BF Campaign Motion',
    client: 'RECKITT BRASIL',
    image: '/images/reckitt/reckitt-mockup.png',
    year: '2024',
    skills: ['UX motion', 'Art Direction' , 'visual design'],
    tools: []
  },
  {
    id: 7,
    name: 'Folder UX',
    client: 'GOOGLE FOR EDUCATION',
    image: '/images/goog/gf-mockup.png',
    year: '2023',
    skills: ['Information Structure ', 'UX editorial', 'Research'],
    tools: []
  },
  {
    id: 6,
    name: '10K Mentorship',
    client: 'MENTORING PROGRAM',
    image: '/images/mentorship/m1.png',
    year: '2024',
    skills: ['UI gamification', 'Art Direction'],
    tools: []
  },
];

// Tool icon mapping (you would replace these with actual imports/paths)
const getToolIcon = (tool) => {
  const icons = {
    figma: '/images/icons/1.png',
    photoshop: '/images/icons/5.png',
    illustrator: '/images/icons/9.png',
    framer: '/images/icons/3.png',
    react: '/images/icons/12.png',
    tailwind: '/images/icons/13.png',
    premiere:'/images/icons/7.png',
    dreamweaver:'/images/icons/2.png',
    dimension:'/images/icons/11.png',
    effects:'/images/icons/6.png',
    indesign:'/images/icons/10.png',
 
  };
  
  return icons[tool.toLowerCase()] || '/icons/default-tool.svg';
};

const PortfolioSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sectionRef = useRef(null);
  const featuredProjectRefs = useRef([]);
  const additionalProjectRefs = useRef([]);
  const [hoveredFeatured, setHoveredFeatured] = useState(null);
  const [hoveredAdditional, setHoveredAdditional] = useState(null);

  // Function to handle navigation to project page
  const handleViewProject = (projectId) => {
    navigate(`/project/${projectId}`, { 
      state: { from: location.pathname } 
    });
  };

  useEffect(() => {
    // Create a GSAP context to easily kill all animations later
    const ctx = gsap.context(() => {
      // Animate featured projects
      featuredProjectRefs.current.forEach((projectEl, index) => {
        if (!projectEl) return;
        
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projectEl,
            start: "top 80%",
            end: "top 30%",
            scrub: 0.2,
            pin: false,
            anticipatePin: 1
          }
        });

        tl.fromTo(projectEl, {
          rotationX: 15,
          transformOrigin: "bottom center",
          opacity: 0.5,
          scale: 0.85
        },
        {
          rotationX: 0,
          opacity: 1,
          scale: 1,
          duration: 0.4,
          ease: "power2.out"
        });
      });
      
      // Animate additional projects
      additionalProjectRefs.current.forEach((projectEl, index) => {
        if (!projectEl) return;
        
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projectEl,
            start: "top 85%",
            end: "top 65%",
            scrub: 0.2,
            pin: false,
            anticipatePin: 1
          }
        });

        tl.fromTo(projectEl, {
          rotationX: 15,
          transformOrigin: "bottom center",
          opacity: 0.5,
          scale: 0.85,
          y: 50
        },
        {
          rotationX: 0,
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 0.4,
          ease: "power2.out"
        });
      });
    });

    // Cleanup function
    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef} className="bg-white py-16"id="latest-works">
      <div className="container mx-auto px-4">
        {/* Featured Projects Section */}
        <h2 className="text-5xl md:text-7xl text-center font-bold mb-8 mt-16 about-text">
          FEATURED PROJECTS
        </h2>
        <div className="grid grid-cols-1 gap-12" style={{perspective: "1500px"}}>
          {featuredProjects.map((project, index) => (
            <div
              key={index}
              ref={el => featuredProjectRefs.current[index] = el}
              className="portfolio-card relative overflow-hidden rounded-xl shadow-lg w-full [transform-style:preserve-3d] border-4 border-black cursor-pointer"
              style={{
                transformOrigin: "bottom center",
                willChange: "transform, opacity",
                height: "90vh"
              }}
              onClick={() => handleViewProject(project.id)}
              onMouseEnter={() => setHoveredFeatured(index)}
              onMouseLeave={() => setHoveredFeatured(null)}
            >
             {/* White header section with project information */}
<div className="absolute top-0 left-0 right-0 bg-white p-8 z-10" style={{ height: "30%" }}>
  <div className="flex justify-between h-full">
    {/* Left column: Project title and client */}
    <div className="flex flex-col justify-between">
      <div>
        <h3 className="text-4xl font-bold mb-2 text-black">{project.name}</h3>
        <p className="text-lg text-gray-700">{project.client}</p>
      </div>
      {/* Project skills/elements */}
      <div className="mt-4">
        {project.skills.map((skill, i) => (
          <span key={i} className="text-sm text-gray-600 block">{skill}</span>
        ))}
      </div>
    </div>
    {/* Right column: Year and tool icons */}
    <div className="flex flex-col items-end justify-between">
      <div className="text-2xl font-bold text-black">{project.year}</div>
      {/* Tool icons - responsive sizes */}
      <div className="flex gap-2 mt-4">
        {project.tools.map((tool, i) => (
          <div key={i} className="w-8 h-8 sm:w-16 sm:w-16 md:w-16 md:h-16">
            <img
              src={getToolIcon(tool)}
              alt={tool}
              className="w-full h-full object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  </div>
</div>
              
              {/* Project image/video section */}
<div className="absolute bottom-0 left-0 right-0" style={{ height: "70%", top: "30%" }}>
  {/* For video content */}
  {project.video && (
    <video
      src={project.video}
      poster={project.image}
      alt={project.name}
      className="hidden md:block w-full h-full object-cover"
      autoPlay
      loop
      muted
      playsInline
      loading="lazy"
    />
  )}
  
  {/* Image fallback - shown on mobile or when no video exists */}
  <img
    src={project.image}
    alt={project.name}
    className={`w-full h-full object-cover ${project.video ? 'md:hidden' : ''}`}
  />
</div>
              
              {/* Hover overlay with "View" button */}
              <div 
                className={`absolute inset-0 bg-black/40 flex items-center justify-center transition-opacity duration-300 ${
                  hoveredFeatured === index ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <div className="rounded-full bg-white px-6 py-3 text-black font-medium">
                  View
                </div>
              </div>
              
              {/* Live link if available (display on hover) */}
              {project.liveLink && (
                <div 
                  className={`absolute bottom-8 right-8 transition-opacity duration-300 ${
                    hoveredFeatured === index ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <a
                    href={project.liveLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white underline hover:text-gray-300"
                    onClick={(e) => e.stopPropagation()}
                  >
                    See Live Website
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
        
        <ProductDesign />
        
        {/* Additional Projects Section */}
        <h2 className="text-3xl md:text-5xl font-bold mt-24 mb-10 about-text">
          Additional Projects
        </h2>
        <div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" 
          style={{perspective: "1500px"}}
        >
          {additionalProjects.map((project, index) => (
            <div
              key={index}
              ref={el => additionalProjectRefs.current[index] = el}
              className="portfolio-card relative overflow-hidden rounded-xl shadow-lg w-full [transform-style:preserve-3d] border-2 border-black cursor-pointer"
              style={{
                transformOrigin: "bottom center",
                willChange: "transform, opacity",
                height: "400px"
              }}
              onClick={() => handleViewProject(project.id)}
              onMouseEnter={() => setHoveredAdditional(index)}
              onMouseLeave={() => setHoveredAdditional(null)}
            >
              {/* White header section with project information */}
              <div className="absolute top-0 left-0 right-0 bg-white p-4 z-10" style={{ height: "35%" }}>
                <div className="flex justify-between h-full">
                  {/* Left column: Project title and client */}
                  <div className="flex flex-col justify-between">
                    <div>
                      <h3 className="text-xl font-bold mb-1 text-black">{project.name}</h3>
                      <p className="text-sm text-gray-700">{project.client}</p>
                    </div>
                    
                    {/* Project skills - more compact for smaller cards */}
                    <div className="mt-2">
                      {project.skills.map((skill, i) => (
                        <span key={i} className="text-xs text-gray-600 block">{skill}</span>
                      ))}
                    </div>
                  </div>
                  
                  {/* Right column: Year and tool icons */}
                  <div className="flex flex-col items-end justify-between">
                    <div className="text-lg font-bold text-black">{project.year}</div>
                    
                    {/* Tool icons */}
                    <div className="flex gap-1 mt-2">
                      {project.tools.map((tool, i) => (
                        <div key={i} className="w-8 h-8 sm:w-8 sm:w-8 md:w-8 md:h-8">
                          <img 
                            src={getToolIcon(tool)} 
                            alt={tool} 
                            className="w-full h-full object-contain"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Project image section */}
              <div className="absolute bottom-0 left-0 right-0" style={{ height: "65%", top: "35%" }}>
                <img
                  src={project.image}
                  alt={project.name}
                  className="w-full h-full object-cover"
                />
              </div>
              
              {/* Hover overlay with "View" button */}
              <div 
                className={`absolute inset-0 bg-black/40 flex items-center justify-center transition-opacity duration-300 ${
                  hoveredAdditional === index ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <div className="rounded-full bg-white px-4 py-2 text-black text-sm font-medium">
                  View
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;