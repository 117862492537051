import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ExternalLink } from 'lucide-react';

const ComingSoonModal = ({ project, isOpen, onClose }) => {
  useEffect(() => {
    // Prevent scrolling on the background when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen || !project) return null;

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.4
      }
    }
  };

  const staggerChildVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 }
  };

  const containerVariants = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div 
        className="fixed inset-0 bg-white z-50 overflow-y-auto"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
      >
        {/* Top navigation bar with close button */}
        <div className="sticky top-0 z-10 bg-white bg-opacity-90 backdrop-blur-sm border-b border-gray-100">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <div className="font-medium text-gray-600">Ariane Souza</div>
              <button 
                onClick={onClose}
                className="p-3 rounded-full bg-black hover:bg-gray-800 transition-colors"
                aria-label="Close project"
              >
                <X className="w-6 h-6 text-white" />
              </button>
            </div>
          </div>
        </div>

        {/* Project content */}
        <motion.div variants={containerVariants}>
          {/* Full width hero image */}
          <motion.div variants={staggerChildVariants} className="w-full relative">
            <img 
              src={project.heroImage} 
              alt={project.title} 
              className="w-full h-96 md:h-[60vh] object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-60"></div>
            <div className="absolute bottom-0 left-0 w-full p-8 md:p-16 text-white">
              <p className="text-gray-200 font-medium mb-2">Coming Soon</p>
              <h1 className="text-4xl md:text-6xl font-light mb-6 leading-tight max-w-4xl">{project.title}</h1>
            </div>
          </motion.div>

          {/* Project details */}
          <motion.div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <motion.section variants={staggerChildVariants} className="mb-16">
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 mb-12">
                <div>
                  <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-2">Client</h3>
                  <p className="font-medium text-gray-700">{project.client}</p>
                </div>
                <div>
                  <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-2">Year</h3>
                  <p className="font-medium text-gray-700">{project.year}</p>
                </div>
                <div>
                  <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-2">Role</h3>
                  <p className="font-medium text-gray-700">{project.role}</p>
                </div>
                <div>
                  <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-2">Tools</h3>
                  <p className="font-medium text-gray-700">{project.tools || "Not specified"}</p>
                </div>
              </div>
              
             {/* Skills section in modal */}
              {project.modalSkills && project.modalSkills.length > 0 && (
                <div className="mb-16">
                  <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-4">Skills</h3>
                  <div className="flex flex-wrap gap-2">
                    {project.modalSkills.map((skill, index) => (
                      <span 
                        key={index} 
                        className="inline-block px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm font-medium"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>
              )}
                            
              {/* Project overview */}
              <div
                className="text-xl leading-relaxed text-gray-700 mb-8"
                dangerouslySetInnerHTML={{
                  __html:
                    typeof project.overview === 'string'
                      ? project.overview
                      : "Project overview not available yet.",
                }}
              />

              
              {/* Coming Soon Message */}
              <div className="mb-16 p-8 bg-gray-50 rounded-lg border border-gray-100">
                <h2 className="text-2xl font-light mb-4 text-gray-800">Project Breakdown Coming Soon</h2>
                <p className="text-lg text-gray-700 leading-relaxed mb-8">
                  I'm currently putting together a detailed presentation of this project. 
                  The complete Project Breakdown will be available by the end of April 12, 2025.
                  In the meantime, you can explore the live Website through the link below.
                </p>
                
                {/* Visit Website Button */}
                <a 
                href={project.link || '/'}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
              >
                <span className="mr-2">Visit Project</span>
                <ExternalLink className="w-4 h-4" />
              </a>
               </div>
            </motion.section>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ComingSoonModal;
