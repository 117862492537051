import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useNavigate, useLocation } from 'react-router-dom';
import ComingSoonModal from './ComingSoonModal'; // Import the modal component

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Product design projects data
const productDesignProjects = [
  {
    id: 10,
    name: 'Soft PWA: A Multiplatform Product Catalog',
    title: 'Soft PWA: A Multiplatform Product Catalog', // For modal
    client: 'ADW Studium',
    image: '/images/storefront/for-pd.png',
    heroImage: '/images/storefront/for-pd.png', // For modal
    year: '2023•2025',
    role: '360° Product Designer, Art Director & Frontend Developer', // Added for modal
    modalSkills: [' Web App UX/UI', 'UX/UI', ' Brand Strategy & Naming',' Logo Design & Visual ',' Frontend Development ',
      '  eCommerce Integration (API)', 'Product Mockups & Asset Creation', 'Social Media Content & Video ','Creative Direction ',
    'Content Strategy ', ' Ongoing Feature Planning'],
    cardSkills: [''],
    tools: ['Figma - ', 'Visual Studio -', 'React - ', 'Node.js -', 'Tailwind','Photoshop -', 'Illustrator -', 
      'After Effects -', 'Premiere -', 'Lightroom - ', 'GitHub - ', 'Jira - ', 'Adobe Express - ','GA4'],
      overview: `
      A multiplatform product catalog designed as a soft PWA, integrating branding, storefront strategy, and visual identity into a seamless browsing experience. Created to support scalable dropshipping while maintaining design consistency across web and social media touchpoints.<br /><br />
      
      <strong>Key Contributions:</strong>
      <ul>
        <li>•Visual Identity Design (logo, palette, typography)</li>
        <li>•UX/UI Design for catalog browsing & navigation</li>
        <li>•Responsive Frontend Development (soft PWA)</li>
        <li>•Brand Strategy & Product Categorization</li>
        <li>•eCommerce Integration & Dropshipping Setup</li>
        <li>•Content Design (social posts, launch materials)</li>
        <li>•Product Mockups & Campaign Assets</li>
        <li>•End-to-End Production & Coordination</li>
      </ul>`,
      
    slug: 'soft-pwa',
    link: 'https://www.adwstudium.com',
    liveLink: 'https://www.adwstudium.com',
  },
  {
    id: 11,
    name: 'BookOS – An Immersive Story Product 1',
    title: 'BookOS – An Immersive Story Product 1', // For modal
    client: 'A. O. Garoi',
    image: '/images/storefront/hallmoc.png',
    heroImage: '/images/storefront/hallmoc.png', // For modal
    year: '2022•2024',
    role: '360° Product Designer, Art Director & Frontend Developer', // Added for modal
    modalSkills: ['Editorial & Book Design', 'UX/UI', ' Frontend Development ','Digital Illustration','Visual Identity & Branding','eCommerce Strategy',
      ' Campaign Design', 'Video Content', 'Digital Marketing & Copywriting','End-to-End Production & Coordination '],
    cardSkills: [''],
    tools: ['Figma - ', 'InDesign - ','Dreamweaver -', 'Vanilla JS - ', 'CSS - ', 'HMTL5 - ', 'Photoshop -', 'Illustrator -', 
      'After Effects -', 'Premiere -', 'Lightroom - ', 'Notion - ', 'Adobe Express - ', 'Amazon Ads - ', 'Facebook Ads - ', 'GA4'],
    liveLink: 'https://www.oakextendedhalloweendays.aogaroi.com/',
    overview: `An immersive storytelling experience blending traditional book design with digital interaction.
    <br />
    <br />
     <strong> Key Contributions:</strong> <br />
      • Editorial & Book Design (cover, title design, internal layout, diagramming)<br />
      • UX/UI for immersive storytelling (narrative-based web design)<br />
      • Digital Illustration (original & AI-assisted visuals for storytelling)<br />
      • Visual Identity & Branding (color palette, mood, type)<br />
      • eCommerce Strategy (Amazon KDP, print & ebook formats)<br />
      • Campaign Design (Instagram posts, giveaway campaigns, promos)<br />
      • Video Content Creation (TikTok/Instagram reels, trailers)<br />
      • Digital Marketing & Copywriting (launch content & CTAs)<br />
      • End-to-End Production & Coordination (from design to publishing)`,

    slug: 'bookos',
    link: 'https://www.oakextendedhalloweendays.aogaroi.com/' 
  },
  {
    id: 12,
    name: 'BookOS – An Immersive Story Product 2',
    title: 'BookOS – An Immersive Story Product 2', // For modal
    client: 'A. O. Garoi',
    image: '/images/storefront/hof-moc.png',
    heroImage: '/images/storefront/hof-moc.png', // For modal
    year: '2021•2024',
    role: '360° Product Designer, Art Director & Frontend Developer', // Added for modal
    modalSkills: ['Editorial & Book Design', 'UX/UI', ' Frontend Development ', 'Digital Illustration','Visual Identity & Branding','eCommerce Strategy',
      ' Campaign Design', 'Video Content', 'Digital Marketing & Copywriting','End-to-End Production & Coordination'],
    cardSkills: [''],
    tools: ['Figma - ', 'InDesign - ','Dreamweaver -', 'Vanilla JS - ', 'CSS - ', 'HMTL5 - ', 'Photoshop -', 'Illustrator -', 
      'After Effects -', 'Premiere -', 'Lightroom - ', 'Notion - ', 'Adobe Express - ', 'Amazon Ads - ', 'Facebook Ads - ', 'GA4'],
      overview: `An immersive storytelling experience blending traditional book design with digital interaction.
      <br />
      <br />
       <strong> Key Contributions:</strong> <br />
        • Editorial & Book Design (cover, title design, internal layout, diagramming)<br />
        • UX/UI for immersive storytelling (narrative-based web design)<br />
        • Digital Illustration (original & AI-assisted visuals for storytelling)<br />
        • Visual Identity & Branding (color palette, mood, type)<br />
        • eCommerce Strategy (Amazon KDP, print & ebook formats)<br />
        • Campaign Design (Instagram posts, giveaway campaigns, promos)<br />
        • Video Content Creation (TikTok/Instagram reels, trailers)<br />
        • Digital Marketing & Copywriting (launch content & CTAs)<br />
        • End-to-End Production & Coordination (from design to publishing)`,
    slug: 'storyverse',
    link: 'https://www.hofindatheresumptionofties.aogaroi.com/' ,
    liveLink: 'https://www.hofindatheresumptionofties.aogaroi.com/',
  },
];



const ProductDesignSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sectionRef = useRef(null);
  const projectRefs = useRef([]);
  const [hoveredProject, setHoveredProject] = useState(null);
  
  // State for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  // Function to handle opening the modal
  const handleViewProject = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
    // Prevent default navigation that was previously set up
    // navigate(`/project/${project.id}`);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // Create a GSAP context to easily kill all animations later
    const ctx = gsap.context(() => {
      // Animate product design projects
      projectRefs.current.forEach((projectEl, index) => {
        if (!projectEl) return;
        
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projectEl,
            start: "top 85%",
            end: "top 60%",
            scrub: 0.2,
            pin: false,
            anticipatePin: 1
          }
        });

        tl.fromTo(projectEl, {
          rotationX: 15,
          transformOrigin: "bottom center",
          opacity: 0.5,
          scale: 0.9,
          y: 40
        },
        {
          rotationX: 0,
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 0.4,
          ease: "power2.out"
        });
      });
    });

    // Cleanup function
    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef} className="bg-white py-16" id="product-design">
      <div className="container mx-auto px-4">
        {/* Product Design Section */}
        <h2 className="text-5xl md:text-7xl text-center font-bold mb-4 mt-16 about-text">
          PRODUCT DESIGN
        </h2>
        <p className="text-xl text-center text-gray-700 mb-12 max-w-3xl mx-auto">
          These products are <strong>end-to-end work</strong>: concept, brand, UX/UI,  marketing and development.
        </p>
        
        <div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10" 
          style={{perspective: "1500px"}}
        >
          {productDesignProjects.map((project, index) => (
            <div
              key={index}
              ref={el => projectRefs.current[index] = el}
              className="portfolio-card relative overflow-hidden rounded-xl shadow-lg w-full [transform-style:preserve-3d] border-2 border-black cursor-pointer"
              style={{
                transformOrigin: "bottom center",
                willChange: "transform, opacity",
                height: "500px" // Medium-sized card, between featured and additional
              }}
              onClick={() => handleViewProject(project)}
              onMouseEnter={() => setHoveredProject(index)}
              onMouseLeave={() => setHoveredProject(null)}
            >
              {/* White header section with project information */}
              <div className="absolute top-0 left-0 right-0 bg-white p-6 z-10" style={{ height: "38%" }}>
                <div className="flex justify-between h-full">
                  {/* Left column: Project title and client */}
                  <div className="flex flex-col justify-between">
                    <div>
                      <h3 className="text-2xl font-bold mb-2 text-black">{project.name}</h3>
                      <p className="text-base text-gray-700">{project.client}</p>
                    </div>
                    
                    {/* Project skills in card */}
                    <div className="mt-3">
                      {project.cardSkills && project.cardSkills.map((skill, i) => (
                        <span key={i} className="text-sm text-gray-600 block">{skill}</span>
                      ))}
                    </div>
                  </div>
                  
                  {/* Right column: Year and tool icons */}
                  <div className="flex flex-col items-end justify-between">
                    <div className="text-xl font-bold text-black">{project.year}</div>
                    
                    
                  </div>
                </div>
              </div>
              
              {/* Project image section */}
              <div className="absolute bottom-0 left-0 right-0" style={{ height: "62%", top: "38%" }}>
                <img
                  src={project.image}
                  alt={project.name}
                  className="w-full h-full object-cover"
                />
              </div>
              
              {/* Hover overlay with "View" button */}
              <div 
                className={`absolute inset-0 bg-black/50 flex items-center justify-center transition-opacity duration-300 ${
                  hoveredProject === index ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <div className="rounded-full bg-white px-5 py-2 text-black font-medium">
                  View
                </div>
              </div>
              
              {/* Live link if available (display on hover) */}
              {project.liveLink && (
                <div 
                  className={`absolute bottom-6 right-6 transition-opacity duration-300 ${
                    hoveredProject === index ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <a
                    href={project.liveLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white underline hover:text-gray-300"
                    onClick={(e) => e.stopPropagation()}
                  >
                    See Live Project
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* ComingSoonModal component */}
      <ComingSoonModal 
        project={selectedProject} 
        isOpen={modalOpen} 
        onClose={handleCloseModal} 
      />
    </div>
  );
};

export default ProductDesignSection;